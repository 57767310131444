"use client";
import React, { useEffect, useState } from "react";
import { getSortedBlogs } from "@/lib/mdxBlogs";
import BlogThumbnail from "@/components/BlogThumbnail";

const LatestBlog = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const { blogs } = await getSortedBlogs(1, 10, "");
      console.log("Fetched blogs:", blogs);
      setBlogs(blogs);
    };

    fetchBlogs();
  }, []);

  return (
    <div>
      <ul>
        {blogs.map((blog) => (
          <li key={blog.id}>
            <BlogThumbnail params={{ blog }} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LatestBlog;
