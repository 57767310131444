"use client";

import { Badge } from "flowbite-react";
import Image from "next/image";
import Link from "next/link";
import slugify from "slugify";

const truncateExcerpt = (excerpt) => {
  return (
    excerpt.split(" ").slice(0, 20).join(" ") +
    (excerpt.split(" ").length > 20 ? "..." : "")
  );
};

const BlogThumbnail = ({ params }) => {
  const { blog } = params;

  console.log(blog);

  return (
    <div
      key={blog.id}
      className="bg-white rounded-2xl overflow-hidden mb-10 mt-4"
    >
      <div className="relative w-full h-[600px] overflow-hidden">
        <Link href={`/blog/${blog.slug}`}>
          <Image
            src={blog.featuredImage}
            alt={blog.title}
            className="w-full h-full object-cover transform transition duration-1000 ease-in-out hover:scale-110"
            width={2048}
            height={1075}
          />
        </Link>
      </div>
      <div className="px-4 my-4 flex flex-col">
        <div className="pb-2 flex flex-wrap gap-2">
          {blog.category.map((cat, index) => (
            <Link
              key={index}
              href={`/category/${slugify(cat, { lower: true })}`}
              className="text-blue-500 hover:text-red-700 mr-2 border-2 py-2 px-2 text-lg"
            >
              <Badge color="info">{cat}</Badge>
            </Link>
          ))}
        </div>
        <h3 className="text-2xl font-semibold my-1">
          <Link
            className="hover:text-red-700 text-black"
            href={`/blog/${blog.slug}`}
          >
            {blog.title}
          </Link>
        </h3>
        <div className="text-gray-600 my-1">
          {new Date(blog.date).toLocaleDateString()}
        </div>
        <div className="my-1">{truncateExcerpt(blog.excerpt)}</div>
        <Link
          className="hover:text-red-700 text-blue-600 text-sm font-semibold uppercase mt-4"
          href={`/blog/${blog.slug}`}
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

export default BlogThumbnail;
