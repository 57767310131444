"use client";

import { getSortedBlogs } from "@/lib/mdxBlogs";
import Link from "next/link";
import { useEffect, useState } from "react";
import slugify from "slugify";
import { Badge } from "flowbite-react";
import Image from "next/image";
import { FaArrowRight } from "react-icons/fa";

const truncateExcerpt = (excerpt) => {
  return (
    excerpt.split(" ").slice(0, 20).join(" ") +
    (excerpt.split(" ").length > 20 ? "..." : "")
  );
};

const FeaturedThirdRow = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const data = await getSortedBlogs(1, 20, "", "", false, 3);
      console.log("Fetched blogs:", data.blogs);
      setBlogs(data.blogs);
    };

    fetchBlogs();
  }, []);

  return (
    <div className="grid grid-cols-3 gap-8">
      {blogs.map((blog) => (
        <div
          key={blog.id}
          className="flex flex-col items-center justify-center bg-white  overflow-hidden mb-10 mt-4"
        >
          <div className="relative w-full overflow-hidden">
            <Link href={`/blog/${blog.slug}`}>
              <Image
                src={
                  blog.featuredImage
                    ? `${blog.featuredImage}`
                    : "/images/blog/top-ai-essay-writer-tools-for-2024.jpg"
                }
                alt={blog.title}
                className="w-full h-full object-cover transform transition duration-1000 ease-in-out hover:scale-110"
                width={2048}
                height={1075}
              />
            </Link>
          </div>
          <div className="px-4 my-4 flex flex-col h-96">
            <div className="pb-2 flex  gap-2">
              {blog.category.map((cat, index) => (
                <Link
                  key={index}
                  href={`/category/${slugify(cat, { lower: true })}`}
                  className="text-blue-500 hover:text-red-700 mr-2 py-2 text-lg"
                >
                  <div className=" hover:underline font-bold" color="info">
                    {cat}
                  </div>
                </Link>
              ))}
            </div>
            <h3 className="text-2xl font-semibold my-1">
              <Link
                className="hover:text-red-700 text-black"
                href={`/blog/${blog.slug}`}
              >
                {blog.title}
              </Link>
            </h3>
            <div className="text-gray-600 my-1">
              {new Date(blog.date).toLocaleDateString()}
            </div>
            <div className="my-1">{truncateExcerpt(blog.excerpt)}</div>
            <div className="flex items-center justify-center lg:justify-start mt-4 h-72">
              <Image
                src="/images/sumeet-shroff-profile-pic.jpg"
                width={80}
                height={80}
                alt="Keerthi Rangan"
                className="w-8 h-8 rounded-full mr-2"
              />
              <span className="text-gray-700">by Sumeet Shroff</span>
            </div>
            <Link
              className="inline-flex items-center bg-blue-500 text-white font-bold py-2 px-4 rounded-full hover:bg-blue-600  transition duration-300 ease-in-out mt-6 w-44"
              href={`/blog/${blog.slug}`}
            >
              <span className="mr-2">Read More</span>
              <span className="bg-blue-700 p-2 rounded-full">
                <FaArrowRight className="text-white w-4 h-4" />
              </span>
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturedThirdRow;
