"use client";

import { getSortedBlogs } from "@/lib/mdxBlogs";
import Link from "next/link";
import { useEffect, useState } from "react";
import slugify from "slugify";
import { Badge } from "flowbite-react";
import Image from "next/image";

const truncateExcerpt = (excerpt) => {
  return (
    excerpt.split(" ").slice(0, 20).join(" ") +
    (excerpt.split(" ").length > 20 ? "..." : "")
  );
};

const FeaturedSecondRowPosts = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      const data = await getSortedBlogs(1, 2, "", "", false, 1);
      console.log("Fetched blogs:", data.blogs);
      setBlogs(data.blogs);
    };

    fetchBlogs();
  }, []);

  return (
    <div className="grid grid-cols-2 gap-4">
      {blogs.map((blog) => (
        <div
          key={blog.id}
          className="flex md:flex-row items-center justify-center bg-white rounded-2xl overflow-hidden mb-10 mt-4"
        >
          <div className="relative w-full overflow-hidden h-56">
            <Link href={`/blog/${blog.slug}`}>
              <Image
                src={blog.featuredImage}
                alt={blog.title}
                className="w-full h-full object-cover transform transition duration-1000 ease-in-out hover:scale-110 "
                width={2048}
                height={1075}
              />
            </Link>
          </div>
          <div className="px-8 my-4 flex flex-col">
            <div className="flex gap-2">
              {blog.category.map((cat, index) => (
                <Link
                  key={index}
                  href={`/category/${slugify(cat, { lower: true })}`}
                  className="text-blue-500 hover:text-red-700 mr-2  py-2  text-lg"
                >
                  <div className=" hover:underline font-bold" color="info">
                    {cat}
                  </div>
                </Link>
              ))}
            </div>
            <h3 className="text-2xl font-bold my-1">
              <Link
                className="hover:text-red-700 text-black"
                href={`/blog/${blog.slug}`}
              >
                {blog.title}
              </Link>
            </h3>
            <div className="text-gray-600 my-1">
              {new Date(blog.date).toLocaleDateString()}
            </div>
            <div className="my-1">{truncateExcerpt(blog.excerpt)}</div>
            <Link
              className="hover:text-red-700 text-blue-600 text-sm font-semibold uppercase mt-4"
              href={`/blog/${blog.slug}`}
            >
              Read More
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeaturedSecondRowPosts;
